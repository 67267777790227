/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "./assets/css/angular-calendar.css";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

@import '../node_modules/animate.css/animate.css';

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

.logo-horizontal {
    height: 40px;
    margin-top: 5px !important;
    cursor: pointer;
}

.logo-centered {
    margin: 0 auto;
    display: block;
    width: 225px;
}

ion-content {
    --background: #d9d9d9;
}

.img-full {
    display: block;
    width: 100%;
}


/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
/* latin */
@font-face {
    font-family: "Dosis";
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/dosis/v12/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7Ml2xME.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
/* ion-content {
    --background: url("./assets/img/bg.jpg");
    background-size: auto;
    background-repeat: repeat;
    background-position: center;
  } */

ion-content {
    --background: #d9d9d9;
}

h1,
h2,
h3,
h4,
h5,
h6,
ion-button,
ion-title {
    font-family: "Lato" !important;
    text-transform: unset !important;
}

* {
    font-family: "Lato" !important;
}

iframe,
video {
    max-width: 100vw !important;
    max-height: 40vh !important;
}

.menu-item {
    ion-icon {
        font-size: 2rem;
    }

    text-align: center;
    width: 100%;
    height: 100px;
    padding-top: 20px;
    padding-bottom: 15px;
    margin: 0;

    h2 {
        margin-top: 5px;
        font-size: 1rem;
    }
}

.card-post {
    border-bottom: 5px solid var(--ion-color-secondary);
}

// .card-post {
//     margin: 0;
//     padding: 0;

//     border-bottom: 5px solid var(--ion-color-secondary);

//     img {
//         object-fit: cover;
//         width: 100%;
//     }

//     h2 {
//         color: var(--ion-color-dark);
//         font-weight: 700 !important;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         line-height: 1em;
//         min-height: 2em;
//         display: -webkit-box;
//         -webkit-line-clamp: 2;
//         /* number of lines to show */
//         -webkit-box-orient: vertical;
//     }

//     p {
//         color: var(--ion-color-dark);
//         font-weight: 300 !important;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         line-height: 1em;
//         min-height: 4em;
//         display: -webkit-box;
//         -webkit-line-clamp: 4;
//         /* number of lines to show */
//         -webkit-box-orient: vertical;

//         .date-txt {
//             float: right;
//             padding-right: 5px;
//         }

//         p {
//             display: inline !important;
//         }
//     }

//     /*   small {
//       color: var(--ion-color-medium);
//       padding-right: 5px;
//     } */

//     ion-grid,
//     ion-row,
//     ion-col {
//         margin: 0;
//         padding: 0 5px;
//     }
// }

.card-post-0 {
    --background: #dfedd7;
    border-bottom: 7px solid #7fb760;
}

.card-post-1 {
    --background: #bfe6f5;
    border-bottom: 7px solid #009cd8;
}

.card-post-2 {
    --background: #f9c7cb;
    border-bottom: 7px solid #e7202e;
}

.card-post-3 {
    --background: #fff4c8;
    border-bottom: 7px solid #ffd425;
}

.card-post-big {
    img {
        height: calc(30vh);
        max-height: 350px;
        object-fit: cover;
    }
}

.card-user {
    margin: 0;
    padding: 0;
    min-height: 260px;

    img {
        height: 80px;
        max-height: 100px;
        object-fit: cover;
        object-position: bottom;
    }

    ion-avatar {
        width: 100px;
        height: 100px;
        -webkit-transform: translateY(-50%);
        transform: translate(-50%, -50%);
        display: block;
        position: absolute;
        left: 50%;

        img {
            height: 100px;
            border: 2px solid white;
        }
    }

    h2 {
        text-transform: capitalize !important;
        text-align: center;
        color: var(--ion-color-dark);
        font-weight: 700 !important;
        margin: 40px auto 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.5em;
        line-height: 1em;
        min-height: 1em;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        /* number of lines to show */
        -webkit-box-orient: vertical;
    }

    p {
        text-align: center;
        color: var(--ion-color-dark);
        font-weight: 300 !important;
        margin: 0 auto 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1em;
        min-height: 1em;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        /* number of lines to show */
        -webkit-box-orient: vertical;

        .date-txt {
            float: right;
            padding-right: 5px;
        }

        p {
            display: inline !important;
        }
    }
}

.item-chat {
    ion-label {
        .info-chat {
            font-size: 11px;
        }

        div {
            background: #ededed;
            padding: 10px 20px;
            border-radius: 10px;

            p {
                font-size: 12px;
            }
        }
    }
}

.input-chat {
    border-top: 5px solid var(--ion-color-primary);

    ion-textarea {
        min-height: 55px;
        font-size: 12px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(var(--ion-color-primary-rgb), 0.3);
    }
}

.profileherorow {
    .profilehero {
        background: transparent !important;
    }
}

.profilehero {
    padding: 10px;

    background: url("./assets/img/bg0.jpg");

    h1 {
        color: var(--ion-color-light) !important;
        margin-bottom: 0px;
    }

    h4 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-weight: 500;
        line-height: 1.2;
    }

    ion-avatar {
        margin: auto;
        width: 125px;
        height: 125px;
        border: 2px solid rgba(var(--ion-color-light-rgb), 0.9);
    }

    ion-button {
        float: right;
        font-size: 0.7rem;
    }

    ion-chip {
        background: rgba(var(--ion-color-light-rgb), 0.8);
    }
}

.item-btn {
    --background: var(--ion-color-tertiary);
    --color: var(--ion-color-light);
    border-radius: 5px;
    margin: 8px 5px;
}

// .buttons-start,
// ion-back-button {
//   position: absolute;
//   left: 0;
//   z-index: 999;
// }

.buttons-end {
    position: absolute;
    right: 0;
    z-index: 999;
}

@media only screen and (max-width: 425px) {
    .post-detail {
        margin: 0;
        padding: 0;
    }
}

@media only screen and (max-width: 425px) {
    .only-movil {
        display: block;
    }

    .only-web {
        display: none;
    }

    .only-movil-margin {
        margin: 0px;
    }
}

@media only screen and (min-width: 426px) {
    .only-movil {
        display: none;
    }

    .only-web {
        display: block;
    }

    .only-movil-margin {
        margin: 10px;
    }
}

.scroll-overflow-on-table {
    width: 100% !important;
    overflow: scroll !important;
}

input {
    font-size: 1.1rem !important;
}

.card-button-0 {
    color: #dfedd7;
    --background: #7fb760;
    border-bottom: 7px solid #dfedd7;
}

.card-button-1 {
    color: #bfe6f5;
    --background: #009cd8;
    border-bottom: 7px solid #bfe6f5;
}

.card-button-2 {
    color: #f9c7cb;
    --background: #e7202e;
    border-bottom: 7px solid #f9c7cb;
}

.card-button-3 {
    color: #fff4c8;
    --background: #ffd425;
    border-bottom: 7px solid #fff4c8;
}

.sc-ion-segment-ios-h.ion-color.sc-ion-segment-ios-s>ion-segment-button {
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.ios ion-note {
    padding-left: 5px;
    margin-left: 0;

    p {
        font-size: 0.8em !important;
    }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 16px;
    }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 16px;
    }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="url"] {
        font-size: 16px;
    }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="url"] {
        font-size: 16px;
    }
}

.content {
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-top: constant(safe-area-inset-top);
    padding-bottom: constant(safe-area-inset-bottom);
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

iframe,
video {
    max-width: unset !important;
    max-height: unset !important;
}

figure {
    margin: 0px !important;
}

ion-searchbar {
    box-shadow: none !important;
    border-radius: 15% !important;
}

.searchbar-input.sc-ion-searchbar-md {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 2rem !important;
}

.no-box-shadow {
    box-shadow: none !important;
    border-bottom: 1px solid var(--ion-color-primary);
}

.img-wrapper {
    display: block;
    overflow: hidden;
    width: 100%;
    max-height: 130px;
    height: 0;
    padding-bottom: 75%;
    background-color: #f1f1f1;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.img-post {
    width: 100%;
    height: auto;
    max-height: 180px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}

.conectimed-card-title {
    height: 48px;
    overflow: hidden;
}

.conectimed-segment {
    --background: #ffffff;
}

.conectimed-segment-button {
    --color-checked: var(--ion-color-tertiary);
    --background-checked: #d1d1d1;
}

.conectimed-item {
    border: 1px solid var(--ion-color-medium);
    border-radius: 8px;
    --padding-start: 0px;
    --padding-top: 0px;
    --padding-bottom: 0px;
    transition: box-shadow 0.2s ease;

    img {
        margin: 0px;
        height: 96px;
        width: 96px;
        object-fit: cover;
        margin-right: 12px;
    }

    ion-label {
        height: 71px;
    }

    *:focus {
        outline: none;
    }
}

.conectimed-item:hover {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.border-radius {
    border-radius: 10px;
}

.video-en-vivo {
    width: 96% !important;
    margin: 2%;
    padding-bottom: 65% !important;
}

.video-en-vivo h2,
.video-en-vivo p,
.video-en-vivo h1 {
    text-align: center;
    font-weight: bold;
    color: white;
    text-shadow: #000000 1px 1px 5px;
}

.read-content {
    max-width: 950px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.separador-h {
    height: 2px;
    border-radius: 1px;
    background-color: var(--ion-color-secondary);
}

.item-alert {
    background-color: var(--ion-color-danger);
    color: var(--ion-color-light);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: solid 1px var(--ion-color-light);
    margin: 0px;
    padding-bottom: 3px;
}

.modal-class-foro {
    --min-height: 80%;
    --min-width: 80%;
    --max-height: 90%;
    --max-width: 90%;
}

.pointer-cursor {
    cursor: pointer;
}

.big-icon {
    font-size: 35px !important;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
    width: 1em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.action-sheet-group {
    flex-shrink: 2;
    overscroll-behavior-y: contain;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    pointer-events: all;

    background: var(--background);
}

.badge-class {
    position: absolute;
    top: 0px;
    margin-right: -32px;
    font-size: 0.6em;
    --padding-start: 5px;
    --padding-end: 5px;
}

.img-modal-class {
    --height: 568px;
    --width: 320px;
    --max-height: 90%;
    --max-width: 90%;
}

.popContenidoMenu {
    --width: 350px;
}

.no-backdrop {
    --backdrop-opacity: 0;
    --box-shadow: 0px 0px 15px -1px #0f183f;
    --background: var(--ion-color-dark);
}

@media only screen and (min-width: 425px) {
    .image-wrap {
        position: relative !important;
        padding-bottom: 80% !important;
        height: 0 !important;
        overflow: hidden !important;
    }

    .image-wrap img {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: auto !important;
        max-height: inherit !important;
    }
}

.btn_eye_icon {
    position: absolute;
    right: 0;
    bottom: 4px;
    background: transparent;

    ion-icon {
        font-size: 22px;
        color: #757575;
    }
}

.btn_eye_icon:focus {
    outline: none !important;
}

.medium-cancel {
    color: var(--ion-color-medium) !important;
    text-transform: none !important;
}

.video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
    height: 100% !important;
    left: 0;
    position: absolute !important;
    top: 0;
    width: 100% !important;
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
    .auto-height {
        --height: 250px;
    }
}

.custom-flex {
    display: inline-block !important;
    width: auto;
    margin: 10px;
}

div#zmmtg-root {
    display: none;
}

body {
    min-width: auto !important;
}

/* New Products PDF Begin*/
.cstm-main-content {
    display: block;
    position: relative;
    max-width: 950px;
    width: 100%;
    margin: 0 auto;
}

.cstm-img-fluid {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 250px;
}

.cstm-orange-button {
    display: block;
    position: relative;
    text-decoration: none;
    background-color: #ff9800;
    color: white;
    padding: 10px;
    border-radius: 10px;
    margin: 10px auto;
    font-weight: bold;
    text-align: center;
    max-width: 125px;
}

.cstm-orange-button a {
    text-decoration: none;
    color: white;
}

.cstm-title {
    color: #0e518b;
    font-weight: bold;
    padding: 0 15px;
}

.cstm-title1 {
    display: block;
    position: relative;
    margin: 0;
    padding: 8px;
    background-color: #0e518b;
    color: white;
    font-weight: bold;
    border-radius: 0px 0px 15px 15px;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
}

.cstm-title2 {
    background-color: #c9c9c9;
    text-align: center;
    padding: 5px;
    font-weight: bold;
    margin-top: 5px;
}

.cstm-main-content {
    padding: 12px;
    font-weight: bold;
}

.cstm-logo-wrapper {
    position: relative;
    display: block;
    border: 5px solid #c9c9c9;
    padding: 8px;
    border-radius: 1px 1px 15px 1px;
    width: 100%;
    min-width: 180px;
    padding-bottom: 100%;
}

.cstm-header1 {
    background-color: #1d788f;
    color: white;
    padding: 10px;
    border-radius: 0px 0px 15px 0px;
}

.cstm-header2 {
    background-color: #0e518b;
    color: white;
    display: inline-block;
    padding: 10px;
    border-radius: 0px 0px 15px 0px;
}

.cstm-header2 a {
    text-decoration: none;
    color: #0e518b;
}

.cstm-header3 {
    background-color: #0e518b;
    color: white;
    display: inline-block;
    padding: 10px;
    border-radius: 0px 0px 15px 0px;
}

.cstm-header3 a {
    text-decoration: none;
    color: #ffffff;
}

.cstm-footer-wrapper1 {
    background-color: #c9c9c9;
    position: relative;
    display: block;
    padding: 8px;
}

.cstm-footer {
    background-color: #0e518b;
    color: white;
    padding: 10px;
    border-radius: 0px 0px 15px 0px;
}

.cstm-footer a {
    text-decoration: none;
    color: white;
}

.cstm-logo-wrapper img {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
}

/*::selection {
    color: #0073ff !important;
  }*/

textarea::selection {
    background: #2184c5 !important;
    color: #ffffff !important;
}

/* New Products PDF End*/

.mce-contextmenu {
    display: none !important;
}

.small-alert {
    --max-width: 100px !important;
    --min-width: 100px !important;
}

.searchbar-input.sc-ion-searchbar-md {
    border: 1px solid var(--ion-color-primary);
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.searchbar-search-icon.sc-ion-searchbar-md,
.searchbar-cancel-button.sc-ion-searchbar-md {
    color: var(--ion-color-secondary);
}

.searchbar-search-icon.sc-ion-searchbar-md {
    top: 2px !important;
    height: 28px;
    width: 28px;
    left: 2px;
    color: var(--ion-color-dark);
}