// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --swiper-theme-color: #f1f1f1;
  --swiper-navigation-color: #f1f1f1;
  --swiper-pagination-color: #f1f1f1;
  --ion-color-primary: #2184c5;
  --ion-color-primary-rgb: 33, 132, 197;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1d74ad;
  --ion-color-primary-tint: #3790cb;

  --ion-color-secondary: #0e518b;
  --ion-color-secondary-rgb: 14, 81, 139;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0c477a;
  --ion-color-secondary-tint: #266297;

  --ion-color-tertiary: #639948;
  --ion-color-tertiary-rgb: 99, 153, 72;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #57873f;
  --ion-color-tertiary-tint: #73a35a;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #fed346;
  --ion-color-warning-rgb: 254, 211, 70;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ba3e;
  --ion-color-warning-tint: #fed759;

  --ion-color-danger: #e51b34;
  --ion-color-danger-rgb: 229, 27, 52;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ca182e;
  --ion-color-danger-tint: #e83248;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-dark-light: #404040;
  --ion-color-dark-light-rgb: rgb(64, 64, 64);
  --ion-color-dark-light-contrast: #ffffff;
  --ion-color-dark-light-contrast-rgb: 255, 255, 255;
  --ion-color-dark-light-shade: #252525;
  --ion-color-dark-light-tint: #5e5d5d;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #ffffff;
  --ion-color-light-tint: #ffffff;

  --ion-color-stable: #dbdfe3;
  --ion-color-stable-rgb: 219, 223, 227;
  --ion-color-stable-contrast: #000000;
  --ion-color-stable-contrast-rgb: 0, 0, 0;
  --ion-color-stable-shade: #c1c4c8;
  --ion-color-stable-tint: #dfe2e6;

  --ion-color-stable-two: #f5f5f5;
  --ion-color-stable-two-rgb: 245, 245, 245;
  --ion-color-stable-two-contrast: #2184c5;
  --ion-color-stable-two-contrast-rgb: 33, 132, 197;
  --ion-color-stable-two-shade: #c1c4c8;
  --ion-color-stable-two-tint: #dfe2e6;
}

.ion-color-dark-light {
  --ion-color-base: var(--ion-color-dark-light);
  --ion-color-base-rgb: var(--ion-color-dark-light-rgb);
  --ion-color-contrast: var(--ion-color-dark-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-light-shade);
  --ion-color-tint: var(--ion-color-dark-light-tint);
}

.ion-color-stable {
  --ion-color-base: var(--ion-color-stable);
  --ion-color-base-rgb: var(--ion-color-stable-rgb);
  --ion-color-contrast: var(--ion-color-stable-contrast);
  --ion-color-contrast-rgb: var(--ion-color-stable-contrast-rgb);
  --ion-color-shade: var(--ion-color-stable-shade);
  --ion-color-tint: var(--ion-color-stable-tint);
}

.ion-color-stable-two {
  --ion-color-base: var(--ion-color-stable-two);
  --ion-color-base-rgb: var(--ion-color-stable-two-rgb);
  --ion-color-contrast: var(--ion-color-stable-two-contrast);
  --ion-color-contrast-rgb: var(--ion-color-stable-two-contrast-rgb);
  --ion-color-shade: var(--ion-color-stable-two-shade);
  --ion-color-tint: var(--ion-color-stable-two-tint);
}

:root {
  --ion-color-stablegreen: #b3cc9e;
  --ion-color-stablegreen-rgb: 179, 204, 158;
  --ion-color-stablegreen-contrast: #000000;
  --ion-color-stablegreen-contrast-rgb: 0, 0, 0;
  --ion-color-stablegreen-shade: #9eb48b;
  --ion-color-stablegreen-tint: #bbd1a8;
}

.ion-color-stablegreen {
  --ion-color-base: var(--ion-color-stablegreen);
  --ion-color-base-rgb: var(--ion-color-stablegreen-rgb);
  --ion-color-contrast: var(--ion-color-stablegreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-stablegreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-stablegreen-shade);
  --ion-color-tint: var(--ion-color-stablegreen-tint);
}

:root {
  ion-alert {
    --max-width: 500px;
    --min-width: 350px;
    --width: 90%;
  }

  ion-avatar {
    width: 70px;
    height: 70px;
    background: #ffffff;
  }

  ion-avatar.small {
    width: 50px;
    height: 50px;
    background: rgb(0 0 0 / 20%);
    text-align: center;
    border: 2px solid #fff;
  }

  ion-thumbnail {
    width: 80px;
    height: 80px;
  }

  ion-popover {
    --width: 300px;
  }
}


/* Grid */

.float-row,
.float,
.size-1,
.size-2,
.size-3,
.size-4,
.size-5,
.size-6,
.size-7,
.size-8,
.size-9,
.size-10,
.size-11,
.size-12 {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.size-1 {
  width: 8.33%;
}

.size-2 {
  width: 16.66%;
}

.size-3 {
  width: 25%;
}

.size-4 {
  width: 33.33%;
}

.size-5 {
  width: 41.66%;
}

.size-6 {
  width: 50%;
}

.size-7 {
  width: 58.33%;
}

.size-8 {
  width: 66.66%;
}

.size-9 {
  width: 75%;
}

.size-10 {
  width: 83.33%;
}

.size-11 {
  width: 91.66%;
}

.size-12 {
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .f-left {
    float: left !important;
  }

  .f-right {
    float: right !important;
  }

  .width-70 {
    width: 70% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .width-50 {
    width: 50% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 991px) {
  .item-folder {
    margin-top: 0 !important;
  }

  .width-70 {
    width: 100% !important;
  }

  .width-50 {
    width: 100% !important;
  }

  .f-left {
    float: none !important;
    width: 100% !important;
  }

  .f-right {
    float: none !important;
    width: 100% !important;
  }
}

ion-card.next-sessions {
  background-position: center center !important;
  background-size: cover !important;
  padding-right: 0 !important;
  box-shadow: none !important;

  ion-card-content {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }

  .excerpt {
    max-height: 40px;
    overflow-y: hidden !important;
  }

  .main-button {
    margin-right: -15px;
    margin-top: -25px;
  }

  .figure-shadow {
    background-color: rgb(0 0 0 / 50%);
  }
}

.custom-icon {
  font-size: 35px;
  top: 8px;
  position: relative;
}

.fold {
  border-radius: 7px 7px 0px 0px;
  margin-bottom: 0 !important;
}

ion-card.fold {
  background: none !important;
  box-shadow: none !important;
}

.fold-title {
  border-radius: 7px 7px 0px 0px;
  width: auto;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  position: relative;
  display: inline-block;
  z-index: 999;
  padding: 12px 17px !important;
}

span.fold {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  background-color: var(--ion-color-primary);
}

swiper-container::part(pagination) {
  position: relative;
  margin-top: 20px;
}

swiper-container::part(bullet-active) {
  background-color: var(--ion-color-primary);
  //background-color: var(--ion-color-medium);
}